import logo from '@/images/logo.svg';

export const WordmarkLogo: React.FC = () => (
  <svg viewBox='0 0 195.75 49.499998' className='logo logo--wordmark' role='img' style={{ width: '400px', height: 'auto' }}>
    <title>CoopLink</title>
    <use xlinkHref='#logo-symbol-wordmark' />
  </svg>
);

export const SymbolLogo: React.FC = () => (
  <img src={logo} alt='Coop' className='logo logo--icon' />
);
